import { Button, Card } from "@mui/material";
import React from "react";

export const OrderCard = ({item, order}) => {
  return (
    <Card className="flex justify-between items-center p-5 ">
      <div className="flex items-center space-x-5">
        <img
          className="h-16 w-16"
          src={item.food.images[0]}
          alt=""
        />
        <div>
          <p>{item.food.food_name}</p>
          <p className="text-gray-400">₹{item.total_price}</p>
        </div>
      </div>
      <div>
        <Button className="cursor-not-allowed" variant= 'text'>{order.orderStatus}</Button>
      </div>
    </Card>
  );
};

export default OrderCard;
