import React from 'react'
import MenuItemTable from './MenuItemTable'


export const RestaurantsMenu = () => {
  return (
    <div className='px-2'>
      <MenuItemTable name={"All Menu Items"}/>
    </div>
  )
}

export default RestaurantsMenu