export const CREATE_MENU_ITEM_REQUEST = 'CREATE_MENU_ITEM_REQUEST';
export const CREATE_MENU_ITEM_SUCCESS = 'CREATE_MENU_ITEM_SUCCESS';
export const CREATE_MENU_ITEM_FAILURE = 'CREATE_MENU_ITEM_FAILURE';

export const GET_MENU_ITEMS_BY_RESTAURANT_ID_REQUEST =
  'GET_MENU_ITEMS_BY_RESTAURANT_ID_REQUEST';
export const GET_MENU_ITEMS_BY_RESTAURANT_ID_SUCCESS =
  'GET_MENU_ITEMS_BY_RESTAURANT_ID_SUCCESS';
export const GET_MENU_ITEMS_BY_RESTAURANT_ID_FAILURE =
  'GET_MENU_ITEMS_BY_RESTAURANT_ID_FAILURE';

export const DELETE_MENU_ITEM_REQUEST = 'DELETE_MENU_ITEM_REQUEST';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_FAILURE = 'DELETE_MENU_ITEM_FAILURE';

export const SEARCH_MENU_ITEM_REQUEST = 'SEARCH_MENU_ITEM_REQUEST';
export const SEARCH_MENU_ITEM_SUCCESS = 'SEARCH_MENU_ITEM_SUCCESS';
export const SEARCH_MENU_ITEM_FAILURE = 'SEARCH_MENU_ITEM_FAILURE';

export const UPDATE_MENU_ITEMS_AVAILABILITY_REQUEST = 'UPDATE_MENU_ITEMS_AVAILABILITY_REQUEST';
export const UPDATE_MENU_ITEMS_AVAILABILITY_SUCCESS = 'UPDATE_MENU_ITEMS_AVAILABILITY_SUCCESS';
export const UPDATE_MENU_ITEMS_AVAILABILITY_FAILURE = 'UPDATE_MENU_ITEMS_AVAILABILITY_FAILURE';
