export const TopCuisines = [
    {
        image:"https://www.feastingathome.com/wp-content/uploads/2020/04/the-best-sourdough-bread-3-e1676670991215.jpg",
        title:"Sourdough bread"
    },
    {
        image:"https://bakewithshivesh.com/wp-content/uploads/2021/05/IMG_9141-scaled.jpg",
        title:"Cheese cake"
    },
    {
        image:"https://www.modernhoney.com/wp-content/uploads/2017/11/Thin-and-Crispy-Chocolate-Chip-Cookies-2.jpg",
        title:"Chocolate chip cookies"
    },
    {
        image:"https://recipes.net/wp-content/uploads/portal_files/recipes_net_posts/2021-02/chocolate-eclair-recipe.jpg",
        title:"Chocolate Eclair"
    },
    {
        image:"https://i0.wp.com/smittenkitchen.com/wp-content/uploads//2010/08/perfect-blueberry-muffins.jpg?fit=1200%2C800&ssl=1",
        title:"Blueberry muffin"
    },
    {
        image:"https://www.mybakingaddiction.com/wp-content/uploads/2014/09/sprinkle-topped-chocolate-donuts-hero.jpg",
        title:"Donuts"
    },
    {
        image:"https://img.delicious.com.au/cqtj-J0D/del/2015/12/cornetti-italian-croissants-24713-1.jpg",
        title:"Croissants"
    },
    {
        image:"https://www.nospoonnecessary.com/wp-content/uploads/2014/06/New-York-Bagels.jpg",
        title:"Bagels"
    },
    {
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQh_wy6-FuBDnAmdS_TTMoYUL09e1dxbxvq-w&s",
        title:"Scones"
    },
    {
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2eENa5qsyzyfaFWY5OeepCwMHL01U_Dtntw&s",
        title:"Cherry Pie"
    }
]
