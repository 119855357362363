
export const FIND_CART_REQUEST = 'FIND_CART_REQUEST';
export const FIND_CART_SUCCESS = 'FIND_CART_SUCCESS';
export const FIND_CART_FAILURE = 'FIND_CART_FAILURE';

export const CLEARE_CART_REQUEST = 'CLEARE_CART_REQUEST';
export const CLEARE_CART_SUCCESS = 'CLEARE_CART_SUCCESS';
export const CLEARE_CART_FAILURE = 'CLEARE_CART_FAILURE';

export const GET_ALL_CART_ITEMS_REQUEST = 'GET_ALL_CART_ITEMS_REQUEST';
export const GET_ALL_CART_ITEMS_SUCCESS = 'GET_ALL_CART_ITEMS_SUCCESS';
export const GET_ALL_CART_ITEMS_FAILURE = 'GET_ALL_CART_ITEMS_FAILURE';

export const ADD_ITEM_TO_CART_REQUEST = 'ADD_ITEM_TO_CART_REQUEST';
export const ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS';
export const ADD_ITEM_TO_CART_FAILURE = 'ADD_ITEM_TO_CART_FAILURE';

export const UPDATE_CARTITEM_REQUEST = 'UPDATE_CARTITEM_REQUEST';
export const UPDATE_CARTITEM_SUCCESS = 'UPDATE_CARTITEM_SUCCESS';
export const UPDATE_CARTITEM_FAILURE = 'UPDATE_CARTITEM_FAILURE';

export const REMOVE_CARTITEM_REQUEST = 'REMOVE_CARTITEM_REQUEST';
export const REMOVE_CARTITEM_SUCCESS = 'REMOVE_CARTITEM_SUCCESS';
export const REMOVE_CARTITEM_FAILURE = 'REMOVE_CARTITEM_FAILURE';

